<template>
  <div>
    <el-result title="权限不足" subTitle="抱歉，您的权限不足,请登录有权限的账号">
  <template slot="icon">
    <svg-icon name="permission" class="icon" />
  </template>
  <template slot="extra">
    <el-button type="primary" @click="login" >登录</el-button>
    <el-button type="primary" @click="back" >返回</el-button>
  </template>
</el-result>
  </div>

  </template>
  
  <script>
  export default {
    methods:{
      back(){
        this.$router.go(-2)
      },
        login(){
        this.$router.push('/login')
      }
    }
  }
  </script>
  
  <style scoped>
  .icon-big{
    width: 100px;
    height: 100px;
  }
  </style>